@font-face {
    font-family: "proximanova-light";
    src: url("./fonts/proximanova-light-webfont.woff2") format("woff2"), url("./fonts/proximanova-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "proximanova-bold";
    src: url("./fonts/proximanova-bold-webfont.woff2") format("woff2"), url("./fonts/proximanova-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "proximanova-semibold";
    src: url("./fonts/proximanova-semibold-webfont.woff2") format("woff2"), url("./fonts/proximanova-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    color: white;
    font-family: "proximanova-light", sans-serif;
    font-size: 22px;
    line-height: 1.4em;
    background-color: #13547a;
    padding: 8em 0 0 0;
    height: 100%;
}

h1,
h2,
h3,
h4 {
    font-family: "proximanova-bold", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 2.5em;
    line-height: 1em;
    margin: 0 0 0.5em 0;
}

div.login h1 {
    margin: 0;
}

h2 {
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
}

h3 {
    font-size: 1.2em;
    line-height: 1.2em;
    margin: 0;
}

h4 {
    margin: 0;
}

h4.order {
    margin: 0.2em 0 0 0;
}

b {
    font-family: "proximanova-bold", sans-serif;
    font-weight: 400;
}

strong {
    font-family: "proximanova-semibold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    position: relative;
}

body.home #root {
    height: 100%;
}

#mainMenuDesktop {
    padding-top: 1em;
}

header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 25px 5%;
    z-index: 1;
    border-bottom: 1px solid transparent;
}

body:not(.home) header {
    background-color: #13547a;
    transition: border-color 0.5s ease-in-out;
}

body.scroll header {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.6); */
    border-color: rgba(255, 255, 255, 0.6);
}

header svg.logo {
    width: 269px;
    height: 50px;
    cursor: pointer;
}

header nav {
    float: right;
}

header ul.footer {
    display: none;
}

header::after {
    content: "";
    display: block;
    clear: both;
}

header nav ul {
    list-style: none;
    margin: 0;
}

header nav li {
    display: inline;
    margin-left: 2em;
    text-transform: uppercase;
    line-height: 54px;
}

header nav li:first-child {
    margin-left: 0;
}

header a.cartIcon {
    float: right;
    position: relative;
    width: 32px;
    cursor: pointer;
    margin-left: 2em;
    margin-top: 6px;
}

header a.cartIcon > div {
    position: absolute;
    right: -4px;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: rgba(200, 0, 0, 0.9);
    color: white;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    font-size: 14px;
}

nav a {
    color: white;
    text-decoration: none;
}

nav span {
    cursor: pointer;
}

nav .active {
    font-family: "proximanova-bold", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#back a {
    position: absolute;
    top: -2em;
    left: 0;
    text-decoration: none;
}

#back a:hover span {
    text-decoration: underline;
}

#back a::before {
    fill: white;
    background-image: url("./assets/images/angle-left-white.svg");
    background-size: 8px 16px;
    display: inline-block;
    vertical-align: top;
    width: 8px;
    height: 16px;
    margin: 7px 0.4em 0 0;
    content: "";
}

div.section {
    display: inline-block;
    border: 2px solid white;
    margin: 0 0 2em 0;
    padding: 0.5em 1.5em;
}

div.section > span {
    cursor: pointer;
    position: relative;
}

div.section > span > svg {
    position: absolute;
    left: -20px;
    top: 0.4em;
    width: 15px;
    height: 15px;
}

div.section.open {
    display: block;
}

div.section > div {
    display: none;
    padding: 1em 0 0.5em 0;
}

div.section.open > span > svg {
    transform: rotate(90deg);
}

div.section.open > div {
    display: block;
}

span.click {
    text-decoration: underline;
    cursor: pointer;
}

form.editAddress label.field {
    margin-top: 1.5em;
}

form label.required::before {
    content: "⁕ ";
    font-size: 0.9em;
    vertical-align: text-top;
}

img.mainImage {
    display: block;
    width: 100%;
    margin-bottom: 2em;
}

label {
    display: block;
}

label.above {
    margin-top: 0.8em;
}

label.above > input {
    margin-top: 0.2em;
}

label.checkbox,
label.radio {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    user-select: none;
    margin-top: 0.3em;
    margin-right: 2em;
    cursor: pointer;
    line-height: 1.3em;
}

label.checkbox > input,
label.radio > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

label.checkbox.disabled,
label.radio.disabled {
    opacity: 0.5;
}

label.checkbox > div.btn,
label.radio > div.btn {
    position: absolute;
    left: 0;
    top: 0.25em;
    height: 19px;
    width: 19px;
    border: 2px solid white;
}

label.radio > div.btn {
    border-radius: 50%;
}

label.checkbox > svg,
label.radio > svg {
    width: 33px;
    height: 20px;
    margin-right: 8px;
    margin-left: 2px;
}

label.checkbox > svg.swiss,
label.radio > svg.swiss {
    width: 20px;
}

label.checkbox > div::after,
label.radio > div::after {
    content: "";
    position: absolute;
    display: none;
}

label.checkbox input:checked ~ div.btn::after {
    display: block;
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    background: white;
}

label.radio input:checked ~ div.btn::after {
    display: block;
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
}

.hidden {
    display: none;
}

div.select {
    position: relative;
}

div.select:not(.disabled):before {
    content: "▾";
    font-size: 2em;
    position: absolute;
    right: 15px;
    top: 6px;
    color: #fff;
    pointer-events: none;
}

hr {
    margin: 2em 0 0 0;
    border: 0;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.6);
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    width: 100%;
    padding: 0.4em 0 0.4em 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-family: "proximanova-semibold", sans-serif;
    font-size: 1em;
    outline: none;
    border-bottom: 2px solid white;
}

select:disabled {
    opacity: 0.5;
}

option {
    background-color: #1b5c7a;
}

textarea,
input[type="text"],
input[type="password"] {
    font-family: "proximanova-semibold", sans-serif;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1em;
    padding: 0.3em 0.5em;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

textarea {
    resize: none;
}

body.home input {
    color: #333;
    background-color: rgba(255, 255, 255, 0.6);
}

div.radioGroup {
    border: 2px dashed transparent;
}

div.invalid {
    position: relative;
}

div.invalid::before {
    position: absolute;
    left: -0.9em;
    top: 0.2em;
    color: #e65151;
    font-size: 2em;
    content: "✘";
}

#rbLogo {
    position: fixed;
    left: 106px;
    top: 28px;
    font-size: 22px;
    line-height: 38px;
    z-index: 2;
    cursor: pointer;
    fill: #fff;
}

#rbLogo svg {
    display: inline-block;
    vertical-align: top;
    width: 38px;
    height: 38px;
    margin-right: 8px;
}

div.window {
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
}

div.window div.error {
    background-color: #d44f50;
    border-bottom: 2px solid white;
    color: white;
    padding: 4px 60px;
    font-size: 0.9em;
}

div.payError > div.error {
    background-color: #d44f50;
    color: white;
    padding: 0.5em 1em;
}

div.payError > div.btn {
    padding: 0.5em 0;
}

div.payError.home {
    margin-bottom: 2em;
}

div.window > div.toBeTreatedManager {
    margin: 5em auto;
    width: 640px;
    max-width: 96%;
    background-color: #236180;
}

div.window > div.ask {
    margin: 5em auto;
    width: 570px;
    max-width: 96%;
    background-color: #236180;
}

div.window > div.ask form {
    margin-bottom: 1em;
}

div.instruction {
    padding-bottom: 1em;
}

div.window div.instruction {
    background-color: white;
    padding: 32px 60px;
    color: #1b5c7a;
    font-family: "proximanova-light", sans-serif;
}

div.window div.instruction p {
    margin: 0;
}

div.window > div.addSymptomFocusing {
    margin: 5em auto;
    width: 570px;
    max-width: 96%;
    background-color: #236180;
    padding: 1px;
}

div.window div.content {
    padding: 1em 60px;
}

div.window > div.addSymptomFocusing > div {
    margin: 0 auto;
}

div.window > div.addSymptomFocusing input {
    margin: 1em 0;
}

div.window textarea::placeholder,
div.window input::placeholder {
    opacity: 0.3;
    color: white;
}

div.window > div.iframe {
    position: absolute;
    left: 2em;
    top: 2em;
    right: 2em;
    bottom: 2em;
    padding-bottom: 4em;
    background-color: #236180;
}

div.window > div.iframe iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 1px solid white;
}

div.window > div.iframe div {
    text-align: center;
    padding-top: 0.3em;
}

#menu {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 355px;
    border-left: 85px solid white;
    height: 100%;
    overflow: visible;
    background-image: url("./assets/images/RB_backgroundLogo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #efefef;
    color: #1b5c7a;
}

body.home:not(.menuOpen) {
    background-image: url("./assets/images/startbild.jpg");
    background-size: cover;
}

#menu div.logo {
    position: absolute;
    left: -85px;
    padding-left: 24px;
    top: 30px;
    font-size: 1em;
    line-height: 38px;
    cursor: pointer;
    fill: #1b5c7a;
}

#menu div.logo svg {
    display: inline-block;
    width: 38px;
    height: 38px;
    vertical-align: top;
    margin-right: 42px;
}

#closeMenu {
    display: none;
}

#menuIcon {
    display: none;
    float: right;
    width: 35px;
    cursor: pointer;
    z-index: 2;
    margin-left: 1.5em;
    margin-top: 10px;
}

#menuIcon div {
    background-color: white;
    height: 1px;
    margin-bottom: 10px;
}

body.menuOpen #menuIcon div:first-child {
    transform: rotate(45deg) translateX(8px);
}

body.menuOpen #menuIcon div:last-child {
    transform: rotate(-45deg) translateX(7px);
}

span.small {
    font-size: 0.82em;
}

div.articleList {
    display: block;
    position: relative;

    padding-bottom: 0.3em;
    margin-top: 1em;
    text-decoration: none;
    color: white;
}

div.articleList.total {
    border-bottom: none;
}

div.articleList.total div.price span {
    border-bottom: 3px double;
    font-family: "proximanova-semibold", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div.articleList {
    display: flex;
    align-items: flex-end;
}

div.articleList div.article {
    flex: 5;
}

div.articleList div.price {
    text-align: right;
    padding-right: 32px;
}

div.articleList p {
    margin: 0;
}

div.articleList svg {
    position: absolute;
    right: 0;
    bottom: 0.3em;
    width: 24px;
    cursor: pointer;
    margin-left: 0.5em;
    margin-bottom: 0.2em;
}

div.invoiceList > div {
    padding: 8px 0;
}

div.invoiceList svg {
    width: 32px;
}

.teaser {
    position: relative;
    padding: 4px 8px;
    margin-top: 1em;
    cursor: pointer;
    border-color: rgba(255, 255, 255, 0.6);
    border-width: 0 0 1px 0;
    border-style: solid;
}

a.teaser {
    display: block;
    text-decoration: none;
}

.teaser svg.arrow {
    position: absolute;
    right: 8px;
    bottom: 14px;
    height: 24px;
    width: 12px;
    fill: currentColor;
}

div.teaserIcon svg {
    width: 20px;
    margin-right: 8px;
    fill: currentColor;
}

.teaser:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.teaser:hover svg.arrow {
    right: 6px;
}

a.remoteTreatmentTeaser {
    display: block;
    text-decoration: none;
}

div.articleTeaser {
    display: flex;
}

div.articleTeaser > div {
    width: 50%;
    border: 2px solid white;
    padding: 2em 1em 1em 1em;
    position: relative;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

div.articleTeaser > div:first-child {
    border-right: none;
}

div.articleTeaser > div:last-child {
    border-left: none;
}

div.articleTeaser div.invert {
    background-color: white;
    color: #1b5c7a;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 2em;
    flex-basis: 60%;
}

div.articleTeaser li {
    position: relative;
    padding: 1em 0;
}

div.articleTeaser li:after {
    content: "";
    position: absolute;
    left: 15%;
    top: 100%;
    width: 70%;
    height: 1px;
    background-color: white;
}

div.articleTeaser li:last-child::after {
    height: 0;
}

div.articleTeaser div.invert li:after {
    background-color: #1b5c7a;
}

div.articleTeaser div.center {
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
}

div.articleTeaser h2 {
    margin-bottom: 1em;
}

div.articleTeaser ul {
    list-style: none;
    padding: 0;
}

div.articleTeaser hr {
    margin: 0.75em auto;
    width: 50%;
    border: 0;
    height: 2px;
    background-color: white;
}

div.articleTeaser.invert hr {
    background-color: #1b5c7a;
}

div.articleTeaser span.currency {
    font-size: 2em;
    font-family: "proximanova-semibold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div.articleTeaser span.money {
    font-size: 4em;
    font-family: "proximanova-bold", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    line-height: 1em;
    display: inline-block;
    margin-bottom: 0.5em;
    padding-left: 0.15em;
    margin-left: 0.35em;
}

div.articleTeaser span.money span.currency {
    position: absolute;
    font-size: 0.5em;
    right: 100%;
    top: -0.3em;
    text-align: right;
}

div.articleTeaser span.money span.month {
    position: absolute;
    font-size: 0.5em;
    right: -1em;
    bottom: -1.5em;
}

div.articleTeaser div.invert button {
    border: 2px solid #1b5c7a;
    color: #1b5c7a;
}

ul.moneyBack {
    list-style: none;
    padding: 0;
}

.center {
    text-align: center;
}

div.inlineError {
    padding-top: 12px;
}

div.inlineError > div {
    display: inline-block;
    background-color: #d44f50;
    border-bottom: 2px solid white;
    color: white;
    padding: 4px 14px;
    font-size: 0.9em;
    position: relative;
}

div.inlineError > div > div {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 36px;
    top: -10px;
    background-color: #d44f50;
    transform: rotate(45deg);
}

div.inlineError svg {
    fill: currentColor;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    vertical-align: middle;
}

div.message {
    background-color: white;
    display: flex;
    margin-bottom: 1em;
}

div.message > div:first-child {
    width: 20%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

div.message svg {
    width: 20px;
}

div.message > div:first-child > div {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: #3fc59d;
    border: 0.25em solid #78d6ba;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.message > div:last-child {
    flex: 5;
    background-color: #3fc59d;
    border-left: 12px solid #78d6ba;
    padding: 1em;
}

div.message.error > div:first-child > div {
    background-color: #d34f4f;
    border-color: #e08383;
}

div.message.error > div:last-child {
    background-color: #d34f4f;
    border-left-color: #e08383;
}

div.message.note > div:first-child > div {
    background-color: #faba45;
    border-color: #f7d69c;
}

div.message.note > div:last-child {
    background-color: #faba45;
    border-left-color: #f7d69c;
}

div.userMessage {
    position: relative;
    padding: 4px 1em 4px 46px;
    border-bottom: 2px solid white;
}

div.userMessage.error {
    background-color: #d34f4f;
}

div.userMessage svg {
    position: absolute;
    left: 14px;
    top: 10px;
    width: 18px;
    fill: currentColor;
}

div.userMessage h4 {
    margin: 0 0 1em 0;
    font-size: 1em;
    font-family: "proximanova-bold";
}

div.userMessage div.message {
    background-color: #24b571;
    border-left: 22px solid #60db9c;
    padding: 40px 16px 40px 64px;
    min-height: 210px;
}

div.note div.message {
    background-color: #f9b944;
    border-color: #f4c572;
}

div.userMessage div.symbol {
    position: absolute;
    left: 61px;
    top: 40px;
    width: 130px;
    height: 130px;
    background-color: #24b571;
    border: 12px solid #60db9c;
    border-radius: 50%;
    line-height: 106px;
    text-align: center;
    font-size: 3em;
}

div.note div.symbol {
    background-color: #f9b945;
    border-color: #ffecbf;
}

div.userMessage div.close {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 2em;
    cursor: pointer;
}

.right {
    text-align: right;
}

.littleSpaceBefore {
    margin-top: 1em;
}

.spaceBefore {
    margin-top: 2em;
}

div.message.spaceAfter,
.spaceAfter {
    margin-bottom: 2em;
}

p {
    margin: 0 0 1em 0;
}

div.line {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding: 1em 0;
    margin: 1em 0 2em 0;
}

div.rightButtonRow {
    text-align: right;
}

div.rightButtonRow > * {
    margin-left: 1em;
}

div.bottomButtons > * {
    float: left;
    margin-right: 0.5em;
}

div.bottomButtons > *:last-child {
    float: right;
    margin-right: 0;
}

div.bottomButtons > .right {
    float: right;
    margin-right: 0;
    margin-bottom: 1em;
}

div.bottomButtons > .left {
    float: left;
}

.smallWide > * {
    float: left;
    width: 76%;
    display: block;
}

.smallWide > *:first-child {
    width: 20%;
    margin-right: 4%;
}

.two > * {
    float: left;
    width: 48%;
    display: block;
}

.two > *:first-child {
    margin-right: 4%;
}

.smallWide::after,
div.bottomButtons::after,
.two::after {
    content: "";
    display: block;
    clear: both;
}

a {
    color: white;
}

#back {
    position: relative;
}

main {
    position: relative;
    max-width: 1293px;
    margin: 0.5em 5% 2em 5%;
    padding-left: 269px;
    padding-bottom: 2em;
}

footer {
    max-width: 1293px;
    margin: 0.5em 5% 0 5%;
    padding-left: 269px;
    padding-bottom: 1em;
    font-size: 0.7em;
}

footer nav ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
}

footer nav li {
    display: inline-block;
    margin-left: 1.5em;
}

li.version {
    opacity: 0.5;
}

footer nav li:first-child {
    margin-left: 0;
}

body.home footer {
    position: absolute;
    right: 5%;
    bottom: 0;
    margin-right: 0;
    padding-left: 0;
    max-width: none;
    margin-left: 0;
}

main div.login {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2em;
}

main div.login input {
    border-bottom: none;
    background-color: #fff;
}

body.home main {
    max-width: 680px;
    position: absolute;
    right: 5%;
    padding: 0;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}

div.narrow {
    width: 450px;
}

a.iconTeaser {
    display: block;
    position: relative;
    padding: 4px 4px 4px 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    cursor: pointer;
    text-decoration: none;
    line-height: 1.2em;
}

a.iconTeaser:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

a.iconTeaser h4 {
    display: block;
    margin: 0;
    font-family: "proximanova-bold";
    font-size: 1em;
}

a.iconTeaser p {
    margin: 0;
    font-size: 0.8em;
}

a.iconTeaser div.icon {
    position: absolute;
    left: 4px;
    top: 8px;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
}

a.iconTeaser div.icon.address {
    background-image: url("./assets/images/profil.svg");
}

a.iconTeaser div.icon.invoice {
    background-image: url("./assets/images/invoice.svg");
}

a.iconTeaser div.icon.moneyBack {
    background-image: url("./assets/images/moneyBack.svg");
}

a.iconTeaser div.icon.eMail {
    background-image: url("./assets/images/edit-email.svg");
}

a.iconTeaser div.icon.payment {
    background-image: url("./assets/images/edit-payment.svg");
}

a.iconTeaser div.icon.password {
    background-image: url("./assets/images/edit-password.svg");
}

a.iconTeaser div.icon.deleteCustomer {
    background-image: url("./assets/images/deleteCustomer.svg");
}

div.two > a.iconTeaser {
    margin-top: 2em;
}

div.soundIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background-image: url("./assets/images/soundFile.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
}

div.tabs {
    font-family: "proximanova-semibold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

div.tabs svg {
    width: 20px;
    margin-right: 8px;
    margin-top: 4px;
    vertical-align: top;
}

div.tabs span {
    color: #0c0;
    padding-left: 0.25em;
    font-size: 2em;
    line-height: 0.75em;
    vertical-align: bottom;
    font-family: "proximanova-semibold";
}

div.tabs > div {
    line-height: 1.5em;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.8em;
    border: 2px solid white;
    border-right: none;
    flex-basis: 50%;
    padding: 0.25em;
}

div.tabs > div:last-child {
    border-right: 2px solid white;
}

div.tabs > div.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

div.tabs > div.selected {
    background-color: white;
    color: #1b5c7a;
}

div.tabs3 > div {
    width: 33.33333%;
}

div.tabs4 > div {
    width: 25%;
}

ul.aboList {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.aboList li {
    position: relative;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    transition: padding-left 0.5s ease-in-out;
}

ul.aboList.change li {
    padding-left: 2em;
    cursor: pointer;
}

ul.aboList div.aboRow {
    display: flex;
    justify-content: space-between;
}

ul.aboList div.aboRow > *:last-child {
    text-align: right;
}

ul.aboList div.payError {
    margin-top: 0.5em;
    text-align: right;
}

ul.aboList div.payError div.error {
    display: inline-block;
}

ul.aboList label {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 8px;
    transition: opacity 0.5s ease-in-out;
}

ul.aboList.change label {
    opacity: 1;
}

ul.aboList img {
    display: inline-block;
    width: 80px;
}

ul.aboList p {
    margin: 0;
}

ul.sound {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.sound > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding-bottom: 8px;
    margin-top: 1em;
    position: relative;
    padding-right: 100px;
}

ul.sound > li:last-child {
    border-bottom: none;
}

ul.sound > li div.date {
    position: absolute;
    right: 0;
    top: 0;
    width: 120px;
    overflow: hidden;
    text-align: right;
    line-height: 32px;
}

div.soundBtn {
    width: 32px;
    height: 32px;
    background-image: url("./assets/images/play.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    cursor: pointer;
}

div.soundBtn.pause {
    background-image: url("./assets/images/pause.svg");
}

div.soundBtn.pending {
    background-image: url("./assets/images/pending.svg");
    cursor: not-allowed;
}

audio {
    outline: none;
}

#askPassword,
div.spinner {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}
.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.sk-circle .sk-child::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-circle .sk-circle2::before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-circle .sk-circle3::before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-circle .sk-circle4::before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-circle .sk-circle5::before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-circle .sk-circle6::before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-circle .sk-circle7::before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-circle .sk-circle8::before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-circle .sk-circle9::before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-circle .sk-circle10::before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-circle .sk-circle11::before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-circle .sk-circle12::before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

#askPassword > div {
    margin: 2em auto;
    width: 320px;
    background-color: white;
    padding: 1em;
    color: #444;
}

div.calendar {
    width: auto;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

div.calendar > nav,
div.calendar > div {
    flex: 1 1 14%;
    text-align: center;
    font-family: "proximanova-light";
}

div.calendar > div {
    border: 1px solid transparent;
}

div.calendar > nav:hover {
    cursor: pointer;
}

div.calendar > nav {
    fill: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin: 0 0 0.5rem 0;
}

div.calendar > nav svg {
    width: 1em;
    height: 1em;
}

div.calendar > nav.right svg {
    transform: scaleX(-1);
}

div.calendar > div.disabled {
    opacity: 0.5;
}

div.calendar > div.head {
    font-family: "proximanova-semibold";
}

div.calendar > div.used {
    background-color: yellow;
    color: #1b5c7a;
    font-family: "proximanova-semibold";
    cursor: not-allowed;
}

div.calendar > div.today {
    background-color: white;
    color: #1b5c7a;
}

div.calendar > div.selected {
    background-color: orange;
    color: #1b5c7a;
    font-family: "proximanova-semibold";
    cursor: pointer;
}

div.calendar > div:not(.head):not(.disabled):not(.used):not(.selected):hover {
    border: 1px solid white;
    cursor: pointer;
}

div.calendar h4 {
    text-align: center;
    font-size: 1.5em;
    line-height: 1.4em;
    margin: 0 0 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    flex-basis: 71.5%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

div.symptomEntries {
    margin-top: 1em;
}

div.symptomEntries > div {
    padding: 0.1em 0 0.1em 16px;
    position: relative;
}

div.symptomEntries span.date {
    margin-right: 8px;
}

div.symptomEntries > div.title {
    margin: 1em 0;
    font-family: "proximanova-bold";
    background-color: rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    padding-left: 16px;
    font-size: 0.8em;
    line-height: 3em;
}

div.symptomEntries > div.entry {
    display: flex;
}

div.symptomEntries > div > div {
    float: left;
}

div.symptomEntries svg {
    position: absolute;
    right: 16px;
    top: 5px;
    width: 24px;
    cursor: pointer;
}

div.symptomEntries div.date {
    width: 5em;
    margin-right: 1em;
}

div.symptomEntries div.content {
    padding-right: 2em;
}

div.toBeTreatedManager div.tabs {
    margin-bottom: 0;
}

div.toBeTreatedManager div.choose {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-top: none;
}

div.toBeTreatedManager div.choose > div {
    padding: 1em 16px 0.5em 16px;
}

ul.toBeTreated {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.toBeTreated li {
    position: relative;
    padding: 0.25em 24px 0.25em 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

ul.toBeTreated li:hover,
ul.toBeTreated li.selected {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

ul.toBeTreated li.selected::before {
    content: "✔ ";
}

ul.toBeTreated svg {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
}

ul.toBeTreated li.selected svg,
ul.toBeTreated li:hover svg {
    display: block;
}

form.paymentType {
    margin-top: 2em;
}

form.paymentType label {
    display: block;
    padding-right: 270px;
    padding-bottom: 1em;
    min-height: 66px;
    margin-right: 0;
    margin-top: 1em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    line-height: 1.3em;
}

form.paymentType label.checkbox {
    padding-right: 0;
}

form.paymentType div.cards {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    height: auto;
    width: auto;
    border: none;
}

form.paymentType div.cards::after {
    content: "";
    display: block;
    clear: both;
}

img.card {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
}

form.paymentType img.card {
    position: static;
    float: right;
    margin-left: 10px;
}

table.invoices {
    border: none;
    width: 100%;
    border-spacing: 0;
}

table.invoices th {
    font-family: "proximanova-semibold", sans-serif;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

table.invoices th.right {
    text-align: right;
}

table.invoices div.payError {
    text-align: right;
}

table.invoices div.payError div.error {
    display: inline-block;
}

/* table.invoices th.pdf {
    width: 7%;
}

table.invoices th.invoiceNr {
    width: 18%;
}

table.invoices th.date {
    width: 18%;
}

table.invoices th.unpaid {
    width: 57%;
} */

table.invoices td {
    padding-top: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

table.invoices tr.payError td {
    border-bottom: none;
}

@media (max-width: 1446px) {
    main {
        padding-left: 0;
    }

    footer {
        padding-left: 0;
    }
}

@media (max-width: 1446px) and (min-width: 1141px) {
    body:not(.home) footer nav,
    body:not(.home) main > div {
        float: right;
        width: 1024px;
    }

    body:not(.home) footer::after,
    body:not(.home) main::after {
        content: "";
        display: block;
        clear: both;
    }
}

@media (max-width: 1140px) {
    footer > nav,
    main > div {
        float: none;
        width: auto;
    }

    body.home header nav li {
        margin-left: 1em;
    }

    body.home header nav li:first-child {
        margin-left: 0;
    }
}

@media (max-width: 1024px) {
    header svg.logo {
        width: 215px;
        height: 40px;
    }

    header nav li {
        margin-left: 1.5em;
        line-height: 40px;
    }

    header a.cartIcon {
        margin-top: 0;
        margin-left: 1.5em;
    }

    body.notAuthenticated #menuIcon {
        display: inline;
    }

    body.notAuthenticated header nav {
        display: none;
        position: fixed;
        left: 0;
        top: 3em;
        width: 100%;
        height: 100%;
        float: none;
        background-color: #13547a;
        font-size: 1.5em;
    }

    body.notAuthenticated.menuOpen header nav {
        display: block;
    }

    body.notAuthenticated header nav ul {
        margin-top: 2em;
    }

    body.notAuthenticated header nav li {
        display: block;
        margin-left: 0;
        text-align: center;
        line-height: 2em;
    }

    body.notAuthenticated header ul.footer {
        display: block;
        margin-top: 5em;
        font-size: 0.75em;
    }

    body.notAuthenticated footer {
        display: none;
    }

    div.articleTeaser {
        display: block;
    }

    div.articleTeaser > div {
        width: auto;
        margin: 0 auto;
        max-width: 500px;
    }

    div.articleTeaser > div:first-child {
        border-right: 2px solid white;
        max-width: calc(500px - 4em);
        border-bottom: none;
    }

    div.articleTeaser > div:last-child {
        border-left: 2px solid white;
        max-width: calc(500px - 4em);
        border-top: none;
    }

    .center {
        text-align: center;
    }

    div.articleTeaser div.invert {
        flex-basis: 60%;
    }
}

@media (max-width: 800px) {
    body {
        font-size: 20px;
    }

    header nav li {
        margin-left: 1em;
        line-height: 40px;
    }

    header a.cartIcon {
        margin-left: 1em;
    }

    #rbLogo {
        top: 12px;
        left: 32px;
    }

    div.window div.instruction {
        padding: 18px 32px;
    }

    div.window div.content {
        padding: 1em 32px;
    }

    body.home main {
        max-width: none;
        margin: 128px 32px 0 32px;
        position: static;
        right: auto;
        top: auto;
        transform: none;
    }

    div.articles {
        display: block;
    }

    div.articleTeaser {
        width: auto;
    }
}

@media (max-width: 680px) {
    body {
        font-size: 18px;
    }

    h1 {
        font-size: 2em;
    }

    div.window div.content {
        padding: 1em 16px;
    }

    #menuIcon {
        display: inline;
    }

    header nav {
        display: none;
        position: fixed;
        left: 0;
        top: 3em;
        width: 100%;
        height: 100%;
        float: none;
        background-color: #13547a;
        font-size: 1.5em;
    }

    body.menuOpen header nav {
        display: block;
    }

    header nav ul {
        margin-top: 2em;
    }

    header nav li {
        display: block;
        margin-left: 0;
        text-align: center;
        line-height: 2em;
    }

    header ul.footer {
        display: block;
        margin-top: 5em;
        font-size: 0.75em;
    }

    footer {
        display: none;
    }

    .two > * {
        float: none;
        width: auto;
    }

    .two > *:first-child {
        margin-right: 0;
    }
}

@media (max-width: 440px) {
    body {
        font-size: 16px;
    }

    #rbLogo {
        left: 16px;
    }

    #menuIcon {
        right: 16px;
    }

    body.home main {
        margin: 80px 16px 0 16px;
    }

    .noColsOnSmartPhone > * {
        float: none;
        width: auto;
        margin-top: 1em;
    }

    .noColsOnSmartPhone > *:first-child {
        margin-right: 0;
    }
}

div.newAbo {
    background-color: white;
    color: #1b5c7a;
    padding: 1em;
}

div.newAbo ul {
    margin: 0;
}

div.newAbo h4 {
    margin: 0 0 0.5em 0;
}

div.choosenDates {
    display: flex;
    margin: 1em 0;
}

div.choosenDates ul {
    margin: 0;
    list-style: none;
    padding-inline-start: 1em;
}

main .ReactPasswordStrength {
    border: none;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    box-sizing: inherit;
}

main .ReactPasswordStrength input {
    border-bottom: none;
}

main .ReactPasswordStrength span {
    display: none;
}

main .ReactPasswordStrength.is-strength-0 span,
main .ReactPasswordStrength.is-strength-1 span,
main .ReactPasswordStrength.is-strength-2 span,
main .ReactPasswordStrength.is-strength-3 span,
main .ReactPasswordStrength.is-strength-4 span {
    display: block;
}

main .ReactPasswordStrength-strength-desc {
    font-style: inherit;
    padding: 0.25em 0.5em;
    top: 2px;
    right: 2px;
    bottom: 4px;
    background-color: black;
    width: auto;
}

main .ReactPasswordStrength-strength-bar {
    top: 0;
    right: 0;
}

div.serviceMode {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1em;
}

.CookieDeclarationType,
.CookieDeclarationTableHeader,
.CookieDeclarationTableCell {
    border-color: white !important;
}
