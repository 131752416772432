.button {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 0.5em 1em;
    display: inline-block;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    font-family: "proximanova-light";
}

.button svg {
    height: 24px;
    width: 12px;
    vertical-align: middle;
    margin-left: 16px;
    margin-right: 2px;
    fill: currentColor;
    transition: 0.15s margin ease;
}

.button.back svg {
    transform: scaleX(-1);
    margin-left: 2px;
    margin-right: 16px;
}

.button.add svg {
    width: 24px;
}

.button.default {
    background-color: white;
    color: #1b5c7a;
    font-family: "proximanova-semibold";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.button.login {
    width: 100%;
    background-color: #13547a;
    color: white;
    border: none;
}

.button.newAbo {
    margin-top: 2em;
    border: 2px solid #1b5c7a;
    color: #1b5c7a;
}

.button.addButton {
    padding: 0.3em 1em;
    font-size: 1em;
    border-radius: 50px;
}

.button:not(.add):hover:enabled svg {
    margin-left: 18px;
    margin-right: 0;
}

.button.back:not(.add):hover:enabled svg {
    margin-left: 0;
    margin-right: 18px;
}

.button:disabled {
    opacity: 0.5;
}

.button:disabled:hover {
    text-decoration: none;
    cursor: not-allowed;
}

@media (max-width: 680px) {
    .button {
        padding: 0.5em 0.5em;
    }

    .button svg {
        height: 16px;
        margin-left: 8px;
    }

    .button:not(.add):hover:enabled svg {
        margin-left: 10px;
    }

    .button.add svg {
        width: 16px;
    }
}
